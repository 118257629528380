<template>
  <v-card rounded="lg" dark>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-img
            height="250px"
            width="500px"
            contain
            class="mx-auto rounded-lg"
            :src="image"
          ></v-img>
        </v-col>

        <v-col cols="12" md="6">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card class="mx-auto" color="red darken-1">
                <v-container fluid>
                  <v-row align="center" justify="center">
                    <v-col class="text-center" cols="6" md="12">
                      <h2>
                        {{ product.name }}
                      </h2>

                      <p class="ma-0">${{ product.price }}</p>
                    </v-col>

                    <v-col class="text-center" cols="6" md="12">
                      <v-row align="center" justify="center">
                        <v-col cols="12" md="4">
                          <h5>Requested</h5>
                          <h3>{{ product.status.requested.toString() }}</h3>
                        </v-col>

                        <v-col cols="12" md="4">
                          <h5>Gifted</h5>
                          <v-btn elevation="0">
                            <h3>{{ getGifted(product.id).toString() }}</h3>
                          </v-btn>
                        </v-col>

                        <v-col cols="12" md="4">
                          <h5>Needed</h5>

                          <v-btn elevation="0">
                            <h3>{{ getNeeded(product).toString() }}</h3>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>

            <v-col class="text-center" cols="6">
              <v-btn
                class="snipcart-add-item"
                large
                :color="
                  getGifted(product.id) !== product.status.requested
                    ? 'orange'
                    : 'pink'
                "
                :disabled="getGifted(product.id) === product.status.requested"
                :data-item-id="product.id"
                :data-item-price="product.price"
                :data-item-url="downloadURL"
                :data-item-description="product.description"
                :data-item-image="product.image.file"
                :data-item-name="product.name"
                :data-item-custom1-name="customConfig.name"
                :data-item-custom1-type="customConfig.type"
                :data-item-custom1-placeholder="customConfig.placeholder"
                :data-item-custom1-required="customConfig.required"
                :data-item-metadata="customConfig.metadata"
              >
                <v-icon
                  v-if="getGifted(product.id) !== product.status.requested"
                >
                  mdi-shopping
                </v-icon>

                <span v-else>Fullfilled</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  storage,
  ref,
  getDownloadURL,
  signInAnonymously,
} from "../../firebase";
import { auth } from "../../firebase";

export default {
  name: "couples-product",

  props: ["product", "couplesID", "totalItemsBought"],

  data: () => ({
    customConfig: {
      name: "Item Message",
      type: "textarea",
      placeholder: "Item Message Here",
      required: "true",
      metadata: {
        couplesID: "",
      },
    },

    image: null,

    downloadURL: "",
  }),

  methods: {
    getNeeded(product) {
      return product.status.requested - this.getGifted(product.id);
    },

    getGifted(productID) {
      let gifted = 0;

      //define match counter
      this.totalItemsBought.forEach((item) => {
        //if customer bought item id matches product id add 1 to match

        if (item.id === productID) {
          gifted += item.quantity;
        }
      });

      return gifted;
    },

    async getProductImage(product, env) {
      try {
        //get product Ref
        const productRef = ref(
          storage,
          `${env}/couples/${this.couplesID}/images/products/${product.id}/${product.image.name}`
        );

        const downloadURL = await getDownloadURL(productRef);

        this.image = downloadURL;
      } catch (err) {
        console.log(err);
      }
    },

    async updateProductInventory(product) {
      let token = process.env.VUE_APP_SNIPCART_SECRET_TEST_KEY;

      if (process.env.VUE_APP_ENV === "stage") {
        token = btoa(process.env.VUE_APP_SNIPCART_SECRET_TEST_KEY);
      }

      if (process.env.VUE_APP_ENV === "prod") {
        token = btoa(process.env.VUE_APP_SNIPCART_SECRET_LIVE_KEY);
      }

      try {
        await axios.put(
          `https://app.snipcart.com/api/products/${product.id}`,
          {
            inventoryManagementMethod: "Single",
            stock: this.getNeeded(product),
          },
          {
            headers: {
              Authorization: `Basic ${token}`,
              Accept: "application/json",
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
    },

    async snipcartValidateJSON(env) {
      //convert metadata to valid JSON
      this.customConfig.metadata = JSON.stringify(this.customConfig.metadata);

      //get url
      const productRef = ref(
        storage,
        `${env}/couples/${this.couplesID}/products/products.json`
      );

      try {
        this.downloadURL = await getDownloadURL(productRef);
      } catch (err) {
        console.log(err);
      }
    },
  },

  created() {
    const env = process.env.VUE_APP_ENV;

    signInAnonymously(auth);

    //set couplesID to snipcarts meta data
    this.customConfig.metadata.couplesID = this.couplesID;

    //exec snipcart validation and set downloadURL
    this.snipcartValidateJSON(env);

    if (this.totalItemsBought.length > 0) {
      //update product stock
      this.updateProductInventory(this.product);

      //set gifted items if customers
      this.getGifted(this.product.id);
    }

    //set image
    this.getProductImage(this.product, env);
  },
};
</script>

<style>
</style>