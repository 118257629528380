<template>
  <v-container v-if="isLoading" fluid>
    <h1 class="text-center red--text text--darken-1">Loading . . .</h1>
  </v-container>

  <v-container v-else fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-img src="@/assets/Images/topPhoto.png">
            <v-container fluid>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card dark class="pa-4" rounded="lg">
                    <h1 class="text-center">
                      {{ partner2Name }} & {{ partner1Name }}
                    </h1>

                    <v-sheet
                      class="mx-auto my-2"
                      width="250"
                      color="red darken-1"
                      rounded="lg"
                    >
                      <h3 class="text-center">
                        {{ dateFormat }}
                      </h3>
                    </v-sheet>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card max-width="700px" dark class="pa-4" rounded="lg">
                    <v-img :src="image"></v-img>
                  </v-card>
                </v-col>

                <v-col
                  v-if="
                    couplesData.page.title.length > 0 &&
                    couplesData.page.description.length > 0
                  "
                  cols="12"
                  md="6"
                >
                  <v-card class="pa-4" dark rounded="lg">
                    <v-card-title>{{ couplesData.page.title }}</v-card-title>

                    <v-card-text>
                      {{ couplesData.page.description }}
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-spacer v-else></v-spacer>
              </v-row>
            </v-container>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4" align="center" justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-img src="@/assets/Images/downPhoto.jpg">
            <v-container fluid>
              <v-row align="center" justify="start">
                <v-col cols="12">
                  <v-card class="pa-4" rounded="lg" dark>
                    <h1 class="text-center">Wedding Registry</h1>
                  </v-card>
                </v-col>

                <v-col
                  v-for="product in couplesData.page.products"
                  :key="product.id"
                  cols="12"
                  md="6"
                >
                  <CouplesProduct
                    :couplesID="$route.params.id"
                    :product="product"
                    :totalItemsBought="combineCustomerItems()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="this.customers.length > 0" align="center" justify="center">
      <v-col cols="12">
        <v-card color="red darken-1" class="pa-4" rounded="lg" dark>
          <h1 class="text-center">Well Wishes</h1>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-timeline dense dark>
          <v-timeline-item
            small
            v-for="customer in customers"
            :key="customer.id"
            color="red darken1"
            fill-dot
          >
            <v-card color="red darken-1">
              <v-card-title>
                {{ customerTitle(customer) }}
              </v-card-title>

              <v-card-text v-for="order in customer.orders" :key="order.id">
                <v-sheet
                  v-for="item in order.items"
                  :key="item.id"
                  rounded="lg"
                  class="pa-2 my-2"
                  dark
                >
                  <h3 class="mb-2">{{ item.name }} has been purchased!</h3>
                  <p class="ma-0">Gift Note: {{ item.giftNote }}</p>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { storage, doc, getDoc, db, ref, getDownloadURL } from "../../firebase";
import CouplesProduct from "../components/CouplesProduct.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "couples-page",

  components: {
    CouplesProduct,
  },

  metaInfo: {
    title: `Couples Page`,
    meta: [
      {
        name: "description",
        content: `Welcome to couples pages!`,
      },
    ],
  },

  data: () => ({
    couplesData: {},

    customers: [],

    isLoading: true,

    image: null,
  }),

  computed: {
    dateFormat() {
      return moment(this.couplesData.page.date).format("MMMM Do, YYYY");
    },

    partner2Name() {
      return `${this.couplesData.partner2.firstName} ${this.couplesData.partner2.lastName}`;
    },

    partner1Name() {
      return `${this.couplesData.partner1.firstName} ${this.couplesData.partner1.lastName}`;
    },

    ...mapState({
      user: (state) => state.user,
    }),
  },

  methods: {
    customerTitle(customer) {
      return `${customer.name}`;
    },

    combineCustomerItems() {
      const totalItemsBought = [];

      this.customers.forEach((customer) => {
        customer.orders.forEach((order) => {
          order.items.forEach((item) => {
            totalItemsBought.push(item);
          });
        });
      });

      return totalItemsBought;
    },

    async getCouple() {
      try {
        const couplesRef = doc(db, "couples", this.$route.params.id);

        const res = await getDoc(couplesRef);

        this.couplesData = res.data();

        //get image store and set couples image
        const imageRef = ref(
          storage,
          `${process.env.VUE_APP_ENV}/couples/${this.$route.params.id}/images/page/${this.couplesData.page.image.name}`
        );

        this.image = await getDownloadURL(imageRef);

        //check if any customers array is empty
        if (this.couplesData.customers.length > 0) {
          //process each customers data
          return this.couplesData.customers.forEach((customer) => {
            this.getCustomers(doc(db, "customers", customer.id));
          });
        }

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async getCustomers(customerRef) {
      try {
        const res = await getDoc(customerRef);

        //construct customer data
        const customer = {
          id: res.data().customerID,
          name: res.data().name,
          email: res.data().email,
          orders: [],
        };

        //fetch orders and update customer orders data
        const parseOrders = new Promise((resolve) => {
          res.data().orders.forEach(async (order) => {
            const orderData = await this.getOrders(doc(db, "orders", order.id));

            resolve(customer.orders.push(orderData));
          });
        });

        //wait for orders to parse
        await parseOrders;

        this.customers.push(customer);

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async getOrders(orderRef) {
      try {
        const res = await getDoc(orderRef);

        //return order
        return res.data();
      } catch (err) {
        console.log(err);
      }
    },
  },

  async created() {
    try {
      await this.getCouple();
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style>
</style>